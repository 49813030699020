import React, { useEffect, useState } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { get } from 'lodash';

import { ContentLoader } from 'shared';
import './styles.scss';

import { ACTIONS } from 'industry/role/definitions/actions';
import { SECTIONS } from 'industry/role/definitions/sections';
import { canPerformAction } from 'industry/role/selectors';
import { determineUserRole as determineUserRoleAction } from 'industry/role/roleActions';

import Workers from './components/Workers';
import Forms from './components/Forms/Forms';
import Shifts from './components/Shifts/Shifts';
import Ratings from './components/Ratings/Ratings';
import ControlPanel from './components/ControlPanel/index';
import WorkingInjuries from './components/WorkingInjuries';
import Employments from './components/Employments/Employments';
import AssignedEquipment from './components/AssignedEquipment/index';

import DepartmentTreeView from './components/Departments';
// import DepartmentsTab from './components/Departments/DepartmentsTab'; // replaced with DepartmentTreeView

const IndustryLocationHumanResources = ({ t, history, location, showWorkingInjuries, hrTabs, determineUserRole, can, roleLoading }) => {
  const [selectedParentTab, setSelectedParentTab] = useState(0);
  const [selectedChildTab, setSelectedChildTab] = useState(0);
  const [department, setDepartment] = useState(null);

  const updateTabStateFromHistory = () => {
    const parentTabIndex = history?.location?.state?.parentTabIndex ?? 0;
    const childTabIndex = history?.location?.state?.childTabIndex ?? 0;
    setSelectedParentTab(parentTabIndex);
    setSelectedChildTab(childTabIndex);
  };

  const initializeTabState = () => {
    setSelectedParentTab(0);
    setSelectedChildTab(0);
    history.replace(location.pathname, { parentTabIndex: 0, childTabIndex: 0 });
  };

  useEffect(() => {
    determineUserRole();

    // Initialize state for fresh page loads if state doesn't exist
    if (!location?.state) initializeTabState();
    else if (location?.state) updateTabStateFromHistory();
  }, [determineUserRole]);

  // Listen for the popstate event to handle back/forward browser navigation
  useEffect(() => {
    window.addEventListener('popstate', updateTabStateFromHistory);
    return () => {
      window.removeEventListener('popstate', updateTabStateFromHistory);
    };
  }, []);

  // This function is used to filter workers by department from another component but is not used in the current implementation of department tree view.
  // eslint-disable-next-line no-unused-vars
  const filterByDepartment = (value) => {
    setSelectedParentTab(0);
    setDepartment(value);

    history.push(location.pathname, { parentTabIndex: 0, childTabIndex: 0 });
  };

  const handleSelectTab = (tabIndex) => {
    const normalizedTabIndex = tabIndex || 0;
    setSelectedParentTab(normalizedTabIndex);
    setSelectedChildTab(0);
    setDepartment(null);

    // Create new history entry with tab state
    history.push(location.pathname, { parentTabIndex: normalizedTabIndex, childTabIndex: 0 });
  };

  const handleSelectChildTab = (childIndex) => {
    const normalizedChildIndex = childIndex || 0;
    setSelectedChildTab(normalizedChildIndex);

    // Create new history entry with tab and child tab state
    history.push(location.pathname, { parentTabIndex: selectedParentTab, childTabIndex: normalizedChildIndex });
  };

  const shouldDisplayTab = (tabName, section) => {
    const defaultHrTabs = hrTabs || [{ name: 'Workers' }];
    return defaultHrTabs.some((o) => o.name === tabName) && can(section, ACTIONS.VIEW);
  };

  const handleChildTabProps = {
    selectedChildTab: selectedChildTab || 0,
    setSelectedChildTab: (childTabIndex) => handleSelectChildTab(childTabIndex),
  };

  return (
    <div className="industry-tab human_resources_container fullscreen">
      {
        roleLoading
          ? <ContentLoader /> :
          <Tabs selectedIndex={selectedParentTab} onSelect={handleSelectTab}>
          <TabList>
            {shouldDisplayTab('Workers', SECTIONS.WORKERS_TABS) && <Tab>{t('page_content.human_resources.workers_tab')}</Tab>}
            {shouldDisplayTab('Employments', SECTIONS.EMPLOYMENTS_TAB) && <Tab>{t('page_content.human_resources.layoffs_tab')}</Tab>}
            {shouldDisplayTab('Departments', SECTIONS.DEPARTMENTS_TAB) && <Tab>{t('page_content.human_resources.departments_tab')}</Tab>}
            {shouldDisplayTab('Shifts', SECTIONS.SHIFTS_TABS) && <Tab>{t('page_content.human_resources.shifts_tab')}</Tab>}
            {shouldDisplayTab('Forms', SECTIONS.FORMS_TABS) && <Tab>{t('page_content.human_resources.forms_tab')}</Tab>}
            {shouldDisplayTab('Assigned equipment', SECTIONS.ASSIGNED_EQUIPMENT_TABS) && <Tab>{t('page_content.human_resources.assigned_equipment_tab')}</Tab>}
            {showWorkingInjuries && can(SECTIONS.WORKING_INJURIES_TAB, ACTIONS.VIEW) && <Tab>{t('page_content.human_resources.injuries.working_injuries')}</Tab>}
            {shouldDisplayTab('Ratings', SECTIONS.RATINGS_TABS) && <Tab>{t('page_content.human_resources.ratings_tab')}</Tab>}
            {shouldDisplayTab('Control panel', SECTIONS.CONTROL_PANEL_TABS) && <Tab>{t('page_content.human_resources.control_panel.parent_tab_control_panel')}</Tab>}
          </TabList>

          {shouldDisplayTab('Workers', SECTIONS.WORKERS_TABS) &&
            <TabPanel>
              <Workers department={department} {...handleChildTabProps} />
            </TabPanel>}

          {shouldDisplayTab('Employments', SECTIONS.EMPLOYMENTS_TAB) &&
            <TabPanel>
              <Employments />
            </TabPanel>}

          {shouldDisplayTab('Departments', SECTIONS.DEPARTMENTS_TAB) &&
            <TabPanel>
              {/* <DepartmentsTab handleShowWorkers={filterByDepartment} /> */} {/* replaced with DepartmentTreeView */}
              <DepartmentTreeView />
            </TabPanel>}

          {shouldDisplayTab('Shifts', SECTIONS.SHIFTS_TABS) &&
            <TabPanel>
              <Shifts {...handleChildTabProps} />
            </TabPanel>}

          {shouldDisplayTab('Forms', SECTIONS.FORMS_TABS) &&
            <TabPanel>
              <Forms {...handleChildTabProps} />
            </TabPanel>}

          {shouldDisplayTab('Assigned equipment', SECTIONS.ASSIGNED_EQUIPMENT_TABS) &&
            <TabPanel>
              <AssignedEquipment {...handleChildTabProps} />
            </TabPanel>}

          {showWorkingInjuries && can(SECTIONS.WORKING_INJURIES_TAB, ACTIONS.VIEW) &&
            <TabPanel>
              <WorkingInjuries />
            </TabPanel>}

          {shouldDisplayTab('Ratings', SECTIONS.RATINGS_TABS) &&
            <TabPanel>
              <Ratings />
            </TabPanel>}

          {shouldDisplayTab('Control panel', SECTIONS.CONTROL_PANEL_TABS) &&
            <TabPanel>
              <ControlPanel {...handleChildTabProps} />
            </TabPanel>}
        </Tabs>
      }
    </div>
  );
};

IndustryLocationHumanResources.propTypes = {
  hrTabs: PropTypes.array,
  t: PropTypes.func.isRequired,
  can: PropTypes.func.isRequired,
  showWorkingInjuries: PropTypes.bool,
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  roleLoading: PropTypes.bool.isRequired,
  determineUserRole: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    showWorkingInjuries: get(state, 'app.companyConfig.config.working_injuries', false),
    hrTabs: get(state, 'app.companyConfig.config.hr_tabs', null),
    roleLoading: get(state, 'role.loading', true),
    can: (section, action, useExceptions = false) => canPerformAction(state, section, action, useExceptions),
  };
}

const mapDispatchToProps = {
  determineUserRole: determineUserRoleAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withTranslation()(IndustryLocationHumanResources)));
