import React, { useState, useRef, useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { get } from 'lodash';

import './styles.scss';

import { Button, ContentLoader } from 'shared';
import { IconDown } from 'shared/Icons';

import {
  getAllDepartmentsTreeView,
  getDepartmentLeaders,
  getDepartmentShifts,
  getAllWorkers,
} from './actions';

import WorkingPlaces from './WorkingPlaces';
import ManagerModal from './ManagerModal';

const DepartmentTreeView = ({ t, companyId, locationId }) => {
  const lastColumnRef = useRef(null);
  const [departments, setDepartments] = useState({
    data: [],
    isLoading: true,
  });
  const [selectedPath, setSelectedPath] = useState([]);
  const [viewWorkingPlace, setViewWorkingPlaces] = useState(false);

  const [workerOptions, setWorkerOptions] = useState([]);
  const [showEditManagerModal, setShowEditManagerModal] = useState(false);

  const addShiftsToDepartments = (deps, shifts) => {
    return deps.map((department) => {
      const depShifts = shifts.filter((s) => s.department.id === department.id);
      const depChildren = department.children?.length > 0 ? addShiftsToDepartments(department.children, shifts) : [];
      return { ...department, children: depChildren, shifts: depShifts };
    });
  };

  const addLeadersToDepartments = (deps, leaders) => {
    return deps.map((department) => {
      const depLeaders = leaders.filter((l) => l.department.id === department.id);
      const depChildren = department.children?.length > 0 ? addLeadersToDepartments(department.children, leaders) : [];
      return { ...department, children: depChildren, leaders: depLeaders };
    });
  };

  const addLeadersAndShiftsToDepartments = (deps, leaders, shifts) => {
    const departmentsWithLeaders = addLeadersToDepartments(deps, leaders);
    return addShiftsToDepartments(departmentsWithLeaders, shifts);
  };

  const findDepartmentInTree = (tree, departmentId) => {
    for (const department of tree) {
      if (department.id === departmentId) return department;
      if (department.children) {
        const found = findDepartmentInTree(department.children, departmentId);
        if (found) return found;
      }
    }
    return null;
  };

  const updateSelectedPath = (path, updatedDepartments) => {
    return path.map((department) => {
      const foundDepartment = findDepartmentInTree(updatedDepartments, department.id);
      if (foundDepartment) {
        return { ...foundDepartment, children: foundDepartment.children?.length > 0 ? updateSelectedPath(department?.children || [], foundDepartment.children) : [] };
      }
      return department;
    });
  };

  const fetchDepartmentLeadersAndShifts = (deps) => {
    Promise.all([getDepartmentLeaders(locationId), getDepartmentShifts(companyId)])
      .then(([leadersRes, shiftsRes]) => {
        const leaders = get(leadersRes, 'data.results', []);
        const shifts = get(shiftsRes, 'data.results', []);
        if (leaders.length === 0 && shifts.length === 0) {
          setDepartments((prevState) => ({ ...prevState, isLoading: false }));
        } else {
          const updatedDepartments = addLeadersAndShiftsToDepartments(deps, leaders, shifts);
          const newSelectedPath = updateSelectedPath(selectedPath, updatedDepartments);
          setSelectedPath(newSelectedPath);
          setDepartments({ data: updatedDepartments, isLoading: false });
        }
      })
      .catch(() => setDepartments({ ...departments, isLoading: false }));
  };

  const fetchDepartments = () => {
    setDepartments({ ...departments, isLoading: true });

    getAllDepartmentsTreeView(locationId)
      .then((res) => {
        const data = get(res, 'data', []);
        if (data.length > 0) {
          setDepartments({ data });
          fetchDepartmentLeadersAndShifts(data);
        } else {
          setDepartments({ data: [], isLoading: false });
        }
      })
      .catch(() => setDepartments({ ...departments, isLoading: false }));
  };

  const fetchWorkers = async () => {
    const res = await getAllWorkers(locationId, companyId, '&is_active=true');
    setWorkerOptions(get(res, 'data.results', []));
  };

  useEffect(() => {
    fetchDepartments();
    fetchWorkers();
  }, []);

  useEffect(() => {
    if (lastColumnRef.current) {
      lastColumnRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
        inline: 'start',
      });
    }
  }, [selectedPath]);

  const handleDepartmentClick = (department, index) => {
    const newPath = selectedPath.slice(0, index + 1);
    setSelectedPath([...newPath, department]);
  };

  const handleBreadcrumbClick = (index) => {
    setSelectedPath(selectedPath.slice(0, index + 1));
  };

  const renderTree = (nodes, index) => (
    <div className="treeView-rows">
      {nodes.map((node) => (selectedPath.find((bc) => bc.id === node.id) ? (
          <div
            key={node.id}
            className={selectedPath?.[selectedPath?.length - 1]?.id === node.id ? 'treeView-row selected last' : 'treeView-row selected'}
            onClick={() => handleDepartmentClick(node, index)}
          >
            <p>{node?.name || '-'}</p>
            {node?.children?.length > 0 ? <IconDown width="20px" height="10px" style={{ transform: 'rotate(-90deg)', paddingRight: '3px' }} /> : null}
          </div>
      ) : (
          <div
            key={node.id}
            className="treeView-row"
            onClick={() => handleDepartmentClick(node, index)}
          >
            <p>{node?.name || '-'}</p>
            {node?.children?.length > 0 ? <IconDown width="20px" height="10px" style={{ paddingRight: '3px' }} /> : null}
          </div>
      )))}
    </div>
  );

  const renderColumns = () => {
    const columns = [];
    const rootNodes = departments.data;

    columns.push(
      <div key="root" className="treeView-column">
        {renderTree(rootNodes, -1)}
      </div>,
    );

    selectedPath.forEach((department, index) => {
      if (department.children && department.children.length > 0) {
        const isLastColumn = index === selectedPath.length - 1;
        columns.push(
          <div
            key={department.id}
            className="treeView-column"
            ref={isLastColumn ? lastColumnRef : null}
          >
            {renderTree(department.children, index)}
          </div>,
        );
      }
    });

    return columns;
  };

  const connectNameAndLastName = (worker) => {
    if (!worker) return '-';
    const { name, last_name } = worker;
    return `${name || '-'} ${last_name || '-'}`;
  };

  const lastSelectedDepartment = selectedPath?.[selectedPath.length - 1] || null;
  const lastSelectedManager = lastSelectedDepartment?.leaders?.find((l) => l.is_manager && l.is_active) || null;
  const lastSelectedDeputyManager = lastSelectedDepartment?.leaders?.find((l) => l.is_deputy_manager && l.is_active) || null;

  const shift1 = lastSelectedDepartment?.shifts?.find((shift) => shift.order === 1);
  const shift2 = lastSelectedDepartment?.shifts?.find((shift) => shift.order === 2);
  const shift3 = lastSelectedDepartment?.shifts?.find((shift) => shift.order === 3);

  return (
    <div className="container">
      {departments?.isLoading ? (
        <ContentLoader />
      ) : (
        <>
          <div className="breadcrumbs">
            {selectedPath.length === 0 ? (
              <div className="empty-breadcrumbs" />
            ) : (
              selectedPath.map((crumb, index) => (
                <span key={index} onClick={() => handleBreadcrumbClick(index)}>
                  <p className="name">{crumb?.name || '-'}</p>
                  <p className="arrow">{index < selectedPath.length - 1 && '>'}</p>
                </span>
              ))
            )}
          </div>
          <div className="treeView-details-container">
            <div className="treeView">
              {!departments?.isLoading && departments?.data?.length > 0 && renderColumns()}
            </div>
            {selectedPath.length > 0 && (
              <div className="details">
                <h2>{lastSelectedDepartment?.name || '-'}</h2>
                <div>
                  <span>
                    <p>{t('page_content.human_resources.departments.table_column_number_of_workers')}:</p><p style={{ fontWeight: '700' }}>{lastSelectedDepartment?.workers_count}</p>
                  </span>
                  {
                    lastSelectedDepartment?.children?.length > 0 &&
                      <span>
                        <p>{t('page_content.human_resources.departments.number_of_workers_including_subdepartments')}:</p><p style={{ fontWeight: '700' }}>{lastSelectedDepartment?.total_workers}</p>
                      </span>
                  }
                </div>
                <div>
                  <Button
                    type="add"
                    onClick={() => setShowEditManagerModal(true)}
                    style={{ marginLeft: 'auto' }}
                  >
                    {t('page_content.human_resources.departments.table_column_edit')}
                  </Button>
                  <span>
                    <p>{t('page_content.human_resources.departments.table_column_leader')}:</p><p style={{ fontWeight: '700' }}>{connectNameAndLastName(lastSelectedManager?.worker)}</p>
                  </span>
                  <span>
                    <p>{t('page_content.human_resources.departments.table_column_leader_substitute')}:</p><p style={{ fontWeight: '700' }}>{connectNameAndLastName(lastSelectedDeputyManager?.worker)}</p>
                  </span>
                </div>
                <div>
                  <span>
                    <p>{t('page_content.human_resources.departments.table_column_shift1')}:</p><p style={{ fontWeight: '700' }}>{shift1 ? `${shift1.begin_time} - ${shift1.end_time}` : '-'}</p>
                  </span>
                  <span>
                    <p>{t('page_content.human_resources.departments.table_column_shift2')}:</p><p style={{ fontWeight: '700' }}>{shift2 ? `${shift2.begin_time} - ${shift2.end_time}` : '-'}</p>
                  </span>
                  <span>
                    <p>{t('page_content.human_resources.departments.table_column_shift3')}:</p><p style={{ fontWeight: '700' }}>{shift3 ? `${shift3.begin_time} - ${shift3.end_time}` : '-'}</p>
                  </span>
                </div>
                <Button
                  type="add"
                  onClick={() => setViewWorkingPlaces(true)}
                >
                  {t('page_content.human_resources.departments.view_working_places_button')}
                </Button>
              </div>
            )}
          </div>
          {
            lastSelectedDepartment && showEditManagerModal &&
              <ManagerModal
                locationId={locationId}
                showModal={showEditManagerModal}
                data={lastSelectedDepartment}
                handleCloseModal={() => setShowEditManagerModal(false)}
                workerOptions={workerOptions}
                refetch={fetchDepartments}
              />
          }
          {
            lastSelectedDepartment?.id && viewWorkingPlace &&
              <WorkingPlaces
                showModal={viewWorkingPlace}
                handleCloseModal={() => setViewWorkingPlaces(false)}
                selectedDepartment={lastSelectedDepartment?.id}
              />
          }
        </>
      )}
    </div>
  );
};

DepartmentTreeView.propTypes = {
  t: PropTypes.func.isRequired,
  companyId: PropTypes.number.isRequired,
  locationId: PropTypes.number.isRequired,
};

const mapStateToProps = (state) => {
  return {
    companyId: get(state, 'app.company.id', null),
    locationId: get(state, 'app.location.id', null),
  };
};

export default connect(mapStateToProps)(withTranslation()(DepartmentTreeView));
