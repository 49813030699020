import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import { defaultDateFormat } from 'shared/constants';
import '../styles.scss';

import RelatedInfoFields from 'shared/RelatedInfoFields/index';

const GeneralInfoTable = ({ t, companyId, workerDetails }) => {
  const getGenderLabel = () => {
    let label = '-';
    if (workerDetails?.gender === 'male') {
      label = t('page_content.workforce_management.worker_details.gender_male');
    }
    if (workerDetails?.gender === 'female') {
      label = t('page_content.workforce_management.worker_details.gender_female');
    }
    return label;
  };

  return (
    <div className="worker-details">
      <div className="worker-details__general-info-table">
        <div className="worker-details__general-info-table__header">{t('page_content.administration.general_information')}</div>
        <div className="worker-details__general-info-table__info_table_content">
          <div className="worker-details__general-info-table__row">{t('page_content.administration.full_name')}:<span>{workerDetails ? `${workerDetails.name} ${workerDetails.last_name}` : '-'}</span></div>
          <div className="worker-details__general-info-table__row">{t('page_content.administration.gender')}:<span>{getGenderLabel()}</span></div>
          <div className="worker-details__general-info-table__row">{t('page_content.administration.age')}:<span>{workerDetails?.date_of_birth ? moment().diff(moment(workerDetails?.date_of_birth), 'years') : '-'}</span></div>
          <div className="worker-details__general-info-table__row">{t('page_content.administration.date_of_birth')}:<span>{workerDetails?.date_of_birth ? moment(workerDetails.date_of_birth).format(defaultDateFormat) : '-'}</span></div>
          <div className="worker-details__general-info-table__row">{t('page_content.administration.email')}:<span>{workerDetails?.email || '-'}</span></div>
          <div className="worker-details__general-info-table__row">{t('page_content.administration.private_email')}:<span>{workerDetails?.private_email || '-'}</span></div>
          <div className="worker-details__general-info-table__row">{t('page_content.administration.mobile_phone')}: <span>{workerDetails?.mobile_phone || '-'}</span></div>
          <div className="worker-details__general-info-table__row">{t('page_content.administration.personal_id')}: <span>{workerDetails?.tax_id || '-'}</span></div>
          <div className="worker-details__general-info-table__row">{t('page_content.administration.address')}:<span>{workerDetails?.address || '-'}</span></div>
          <div className="worker-details__general-info-table__row">{t('page_content.administration.education_level')}:<span>{workerDetails?.qualification?.name || '-'}</span></div>
          <div className="worker-details__general-info-table__row">{t('page_content.administration.working_place')}:<span>{workerDetails?.worker_working_places?.length > 0 ? workerDetails.worker_working_places.map((w) => w.workingPlaceName).join(', ') : '-'}</span></div>
          <div className="worker-details__general-info-table__row">{t('page_content.administration.department')}: <span>{workerDetails?.department ? workerDetails.department : workerDetails?.location_department ? workerDetails?.location_department?.name : '-'}</span></div>
          <div className="worker-details__general-info-table__row">{t('page_content.administration.default_shift')}:<span>{workerDetails?.department_shift ? `${workerDetails?.department_shift?.name} (${moment(workerDetails?.department_shift?.begin_time, 'HH:mm:ss').format('HH:mm')} - ${moment(workerDetails?.department_shift?.end_time, 'HH:mm:ss').format('HH:mm')})` : '-'}</span></div>
          <div className="worker-details__general-info-table__row">{t('page_content.administration.employment_start')}:<span>{workerDetails?.active_from ? moment(workerDetails.active_from).format(defaultDateFormat) : '-'}</span></div>
          <div className="worker-details__general-info-table__row">{t('page_content.administration.total_seniority')}:<span>
              { workerDetails?.seniority_years || workerDetails?.seniority_months || workerDetails?.seniority_days
                ? `${workerDetails?.seniority_years ? `${workerDetails.seniority_years}${t('page_content.human_resources.workers.column_seniority_in_y')}` : ''} 
                  ${workerDetails?.seniority_months ? `${workerDetails.seniority_months}${t('page_content.human_resources.workers.column_seniority_in_m')}` : ''} 
                  ${workerDetails?.seniority_days ? `${workerDetails.seniority_days}${t('page_content.human_resources.workers.column_seniority_in_d')}` : ''}`
                : '-' }
            </span></div>
          <div className="worker-details__general-info-table__row">{t('page_content.administration.seniority_on_date')}:<span>{workerDetails?.seniority_date ? moment(workerDetails.seniority_date).format(defaultDateFormat) : '-'}</span></div>
          <div className="worker-details__general-info-table__row">{t('page_content.administration.column_ratings_all_working')}:<span>
              { Object.keys(workerDetails?.all_working || {}).length > 0
                ? `${workerDetails?.all_working?.years ? `${workerDetails.all_working.years}${t('page_content.human_resources.workers.column_seniority_in_y')}` : ''} 
                  ${workerDetails?.all_working?.months ? `${workerDetails.all_working.months}${t('page_content.human_resources.workers.column_seniority_in_m')}` : ''} 
                  ${workerDetails?.all_working?.days ? `${workerDetails.all_working.days}${t('page_content.human_resources.workers.column_seniority_in_d')}` : ''}`
                : '-' }
            </span></div>
          <div className="worker-details__general-info-table__row">{t('page_content.administration.kfk_seniority')}:<span>
              { Object.keys(workerDetails?.company_working || {}).length > 0
                ? `${workerDetails?.company_working?.years ? `${workerDetails.company_working.years}${t('page_content.human_resources.workers.column_seniority_in_y')}` : ''}
                  ${workerDetails?.company_working?.months ? `${workerDetails.company_working.months}${t('page_content.human_resources.workers.column_seniority_in_m')}` : ''}
                  ${workerDetails?.company_working?.days ? `${workerDetails.company_working.days}${t('page_content.human_resources.workers.column_seniority_in_d')}` : ''}`
                : '-' }
            </span></div>
          <div className="worker-details__general-info-table__row">{t('page_content.administration.work_permit')}:<span>{workerDetails?.work_permit_valid_until ? moment(workerDetails?.work_permit_valid_until).format(defaultDateFormat) : '-'}</span></div>

          <RelatedInfoFields companyId={companyId} workerId={workerDetails?.id} isWorkerDetails isAdministrationWorkerDetails />

          <div className="worker-details__general-info-table__row">{t('page_content.human_resources.workers.input_label_info')}:<span>{workerDetails?.note || '-'}</span></div>
        </div>
      </div>
    </div>
  );
};

GeneralInfoTable.propTypes = {
  t: PropTypes.func.isRequired,
  companyId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  workerDetails: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
};

export default (withTranslation()(GeneralInfoTable));
