import React, { useState } from 'react';
import Select from 'react-select';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import { Modal } from 'shared';
import { selectModalStyles } from 'styles/modules/reactSelect';

import { editDepartmentLeaders, saveDepartmentLeaders } from './actions';

const ManagerModal = ({ t, locationId, showModal, handleCloseModal, data, workerOptions, refetch }) => {
  const manager = data?.leaders?.find((l) => l.is_manager && l.is_active) || null;
  const deputyManager = data?.leaders?.find((l) => l.is_deputy_manager && l.is_active) || null;

  const [selectValues, setSelectValues] = useState({
    leader: manager?.worker || null,
    substitute: deputyManager?.worker || null,
  });

  const handleSelect = (e, type) => {
    setSelectValues((prevState) => ({ ...prevState, [type]: e }));
  };

  const updatePreviousLeaders = async (dep, id, workerId) => {
    const newData = {};
    newData.is_active = false;
    newData.active_to = new Date();
    newData.worker = workerId;
    newData.department = dep.id;
    await editDepartmentLeaders(locationId, id, newData);
  };

  const handleSaveLeaders = async () => {
    const leaderData = { location: locationId, department: data.id, active_from: new Date() };
    const substituteData = { location: locationId, department: data.id, active_from: new Date() };

    if (manager && (manager?.worker?.id !== selectValues?.leader?.id)) {
      updatePreviousLeaders(data, manager?.id, manager?.worker?.id);
    }
    if (deputyManager && (deputyManager?.worker?.id !== selectValues?.substitute?.id)) {
      updatePreviousLeaders(data, deputyManager?.id, deputyManager?.worker?.id);
    }

    const leader = selectValues?.leader;
    const substitute = selectValues?.substitute;

    if (leader && leader?.id !== manager?.worker?.id) {
      leaderData.worker = leader.id;
      leaderData.is_manager = true;
      leaderData.is_deputy_manager = false;
      leaderData.is_active = true;
      await saveDepartmentLeaders(leaderData);
    }
    if (substitute && substitute?.id !== deputyManager?.worker?.id) {
      substituteData.worker = substitute.id;
      substituteData.is_deputy_manager = true;
      substituteData.is_manager = false;
      substituteData.is_active = true;
      await saveDepartmentLeaders(substituteData);
    }
    refetch();
    handleCloseModal();
  };

  return (
    <Modal
      title={`${t('page_content.human_resources.departments.modal_title1')} "${data?.name}" ${t('page_content.human_resources.departments.modal_title2')}`}
      isOpen={showModal}
      handleSave={handleSaveLeaders}
      handleClose={handleCloseModal}
    >
      <div style={{ padding: 10 }}>
        <div className="modal_row">
          <div className="left_text">{t('page_content.human_resources.departments.modal_leader_label')}</div>
          <div className="right_select">
            <Select
              options={workerOptions}
              placeholder={t('page_content.human_resources.departments.dropdown_default')}
              getOptionLabel={(option) => `${option.name} ${option.last_name}`}
              getOptionValue={(user) => user.id}
              menuPosition="fixed"
              onChange={(e) => handleSelect(e, 'leader')}
              value={workerOptions.find((val) => val.id === selectValues?.leader?.id) || ''}
              styles={selectModalStyles}
            />
          </div>
        </div>
        <div className="modal_row">
          <div className="left_text">{t('page_content.human_resources.departments.modal_substitute_label')}</div>
          <div className="right_select">
            <Select
              options={workerOptions}
              placeholder={t('page_content.human_resources.departments.dropdown_default')}
              getOptionLabel={(option) => `${option.name} ${option.last_name}`}
              getOptionValue={(user) => user.id}
              menuPosition="fixed"
              onChange={(e) => handleSelect(e, 'substitute')}
              value={workerOptions.find((val) => val.id === selectValues?.substitute?.id) || ''}
              styles={selectModalStyles}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

ManagerModal.propTypes = {
  t: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  showModal: PropTypes.bool.isRequired,
  handleCloseModal: PropTypes.func.isRequired,
  workerOptions: PropTypes.array.isRequired,
  locationId: PropTypes.number.isRequired,
  refetch: PropTypes.func.isRequired,
};

export default (withTranslation()(ManagerModal));
